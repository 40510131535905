import React, {useState} from 'react';
import PropTypes from 'prop-types';
import QuantityCounter from '../common/QuantityCounter';
import {useCart} from '../common/CartContext';
import Modal from '../common/Modal';
import {Link} from 'react-router-dom';

/**
 * Component that displays information for a product.
 * @param {function} product
 * @param {function} setProduct
 * @return {Component}
 */
function ProductInfo({product, setProduct}) {
  const [, setCart] = useCart();
  const [displayModal, setDisplayModal] = useState(false);
  const [quantity, setQuantity] = useState(1);

  /**
   * Goes back to the list of products.
   */
  function handleBackButtonClick() {
    setProduct(null);
  }

  /**
   * Adds the currently selected item * quantity to the cart.
   * If the item is already in the cart, its quantity will be
   * incremented by the currently selected quantity.
   */
  function handleAddToCart() {
    setCart( (cart) => {
      if (!itemInCart(cart, product.node.id)) {
        const newProduct = {product: product.node, quantity: quantity};
        return [newProduct, ...cart];
      }
      return cartWithIncrementedQuantity(cart, product.node.id, quantity);
    });
    setDisplayModal((d) => !d);
  }

  /**
   * Checks if the specified product is in the cart.
   * @param {Array<Object>} cart
   * @param {Number} id id of the item
   * @return {Boolean}
   */
  function itemInCart(cart, id) {
    for (const item of cart) {
      if (item.product.id === id) {
        return true;
      }
    }
    return false;
  }

  /**
   * Increments the quantity of a specific item in a cart,
   * then returns the cart.
   * @param {Array<Object>} cart
   * @param {string} id id of the item to increment
   * @param {Number} quantity amount to increment by
   * @return {Array<Object>} cart
   */
  function cartWithIncrementedQuantity(cart, id, quantity) {
    const incrementedCart = cart.map((item) => {
      if (item.product.id === id) {
        const incrementedItem = {...item, quantity: item.quantity + quantity};
        return incrementedItem;
      }
      return item;
    });
    return incrementedCart;
  }

  /**
   * Close the modal.
   */
  function closeModal() {
    setDisplayModal(false);
  }

  return (
    <div className="product-info-container" >
      <Modal visible={displayModal}
        text={'Item(s) added to cart'}
        close={closeModal}/>
      <h1>{product.node.title}</h1>
      <div>
        <img className="product-image"
          src={product.node.featuredImage.originalSrc} />
      </div>
      <div className="back-button-container">
        <button className="clickable" onClick={() => handleBackButtonClick()}>
          Back
        </button>
      </div>
      {product.node.title.includes("Yeti") &&
        <div className="product-notice-container">
          Starting April 30, 2025, Deadline 10 will no longer offer UBL support for Yeti. You can use your previously purchased hours until September 30, 2025, but new purchases will not be available.
          <br />
          <br />
          For continued use, please utilize BYOL (Bring Your Own License). For any questions or assistance, please contact Sales at&nbsp; 
          <a href="mailto:support@awsthinkbox.zendesk.com">support@awsthinkbox.zendesk.com</a>
          .
        </div>
      }
      <div
        dangerouslySetInnerHTML={{__html: product.node.descriptionHtml}}>
      </div>
      <p>
        {' '}
        <b>
          <i>
            Note that this product may have additional requirements. Please
            review the
            <Link to="/requirements" 
              className="clickable-link">
              {' '}Usage Based Licensing Requirements{' '}
            </Link>
            before you purchase
            this product.{' '}
          </i>
        </b>
      </p>
      <p>
        Also note that some additional steps are required to use 3rd Party
        Licensing, so please visit the
        <a className="clickable-link" target="_blank" rel="noopener noreferrer" href="https://www.awsthinkbox.com/ubl-info"> Deadline website </a>
        before proceeding with
        your purchase.
      </p>
      <div className="add-to-cart-row">
        <div>Quantity</div>
        <QuantityCounter quantity={quantity} setQuantity={setQuantity}/>
        <div>${Number(quantity * product.node.priceRangeV2.maxVariantPrice.amount).toFixed(2)}</div>
        <button className="orange-button" onClick={() => handleAddToCart()}>Add to Cart</button>
      </div>
    </div>
  );
}

ProductInfo.propTypes = {
  product: PropTypes.object,
  setProduct: PropTypes.func,
};

export default ProductInfo;
