import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import QuantityCounter from '../common/QuantityCounter';

/**
 * Component that reprents items in the cart.
 * @param {Object} item cart item
 * @param {Function} removeItem function for removing an item from the cart
 * @param {Function} setItemQuantity function for setting the quantity of an
 * item in the cart
 * @return {Component}
 */
function CartItem({item, removeItem, setItemQuantity}) {
  const [quantity, setQuantity] = useState(item.quantity);
  // When quantity changes, update cart quantity
  useEffect(() => setItemQuantity(quantity), [quantity]);

  /**
   * Calls the removeItem prop function, passing in the product id.
   */
  function remove() {
    removeItem(item.product.id);
  }


  return (
    <div className="cart-item-container">
      <div className="cart-item-sub-box">
        <div className="cart-item-name-remove">
          <div className="cart-item-name">{item.product.title}</div>
          <div className="cart-item-remove"
            onClick={() => remove()}>Remove</div>
            <span className="cart-item-notice">This product will be unavailable for use after September 30</span>
        </div>
        <img className="cart-item-image"
          src={item.product.featuredImage.originalSrc}/>
      </div>
      <div className="cart-item-quantity">
        <QuantityCounter
          quantity={item.quantity}
          setQuantity={setQuantity} />
      </div>
      <div className="cart-item-total">
        ${Number(item.product.priceRangeV2.maxVariantPrice.amount * item.quantity).toFixed(2)}
      </div>
    </div>
  );
}

CartItem.propTypes = {
  item: PropTypes.object,
  removeItem: PropTypes.func,
  setItemQuantity: PropTypes.func,
};

export default CartItem;
