import React from 'react';
import '../App.css';

/**
 * Component for the FAQ section of the website.
 * @return {Component}
 */
function FAQ() {
  const questionsAndAnswers = [
    {question: 'Why are we deprecating the Thinkbox UBL Marketplace?', answer: 'We are transitioning to AWS Deadline Cloud UBL to provide customers with a more modern and flexible licensing experience. AWS Deadline Cloud UBL offers postpaid licensing options, improved scalability, and simplifies integration with Deadline 10 workflows, making it a superior alternative to the Thinkbox UBL Marketplace.  Additionally, AWS Deadline Cloud UBL offers a Pay-as-you-go model, billed monthly on your AWS bill, providing increased flexibility.'},
    {question: 'When will the Thinkbox UBL Marketplace stop operating?', answer: 'AWS Deadline Cloud UBL currently supports Autodesk Maya, Autodesk Arnold, Autodesk 3ds Max, Foundry Nuke, SideFX Houdini, SideFX Mantra, SideFX Karma, Keyshot, Maxon Cinema4D, and Maxon Redshift. These products will be removed from the Thinkbox UBL Marketplace on 30th April 2025, with an exception for V-Ray and RenderMan. V-Ray customers and RenderMan will continue to be able to purchase UBL hours from Thinkbox UBL Marketplace until support is available in AWS Deadline Cloud UBL, which is expected by Q2 2025. The Marketplace will be fully deprecated on September 30, 2025.'},
    {question: 'Can I still purchase UBL hours for Deadline 10?', answer: 'Starting April 30, 2025, customers can no longer purchase UBL from the Thinkbox UBL Marketplace hours for all the products except VRay and RenderMan. VRay UBL will remain available in limited quantities until 30th of June 2025 or the day it supported in AWS Deadline Cloud UBL.'},
    {question: 'Can I still use Yeti with Deadline or AWS Deadline Cloud without UBL?', answer: 'Yes, you can use BYOL (Bring Your Own License) for Yeti with both Deadline 10 and AWS Deadline Cloud. While our goal is to offer UBL for as many products as possible, BYOL ensures that customers can continue to render their workloads without interruption.'},
    {question: 'What happens to my unused UBL hours?', answer: 'We will refund any unused credits. Please reach out to sales (support@awsthinkbox.zendesk.com) for questions on refunds.'},
    {question: 'What is the plan for unsupported products, Renderman and V-Ray ?', answer: 'We are working on adding support for V-Ray and RenderMan. Customers can continue purchasing VRay and Renderman UBL hours through the Marketplace until the support is added to AWS Deadline Cloud UBL, expected in Q2 2025. We will keep you updated on our progress.'},
    {question: 'I\'m seeing "END OF MONTH INVOICE", and "BALANCE TRANSFERRED" under my order details. What do these mean?', answer: 'If you have placed any pre-approved orders in the past month, those orders will be collected into a single invoice and sent to you at the end of the month. These end of month invoice orders will be marked with “END OF MONTH INVOICE”, and the pre-approved orders will be marked with “BALANCE TRANSFERRED”.'},
    {question: 'I just placed an order but I have not received my invoice.', answer: 'It can take up to 2 business days before you receive your invoice. Thank you for your patience.'},
    {question: 'I paid my invoice but the Usage Based render time has not been added to my account.', answer: 'It can take up to 2 business days to confirm payment and add the render time to your account.'},
    {question: 'I placed an order that was automatically fulfilled without payment. When will I receive my invoice?', answer: 'We will send you an single invoice for any outstanding orders at the end of each month.'},
    {question: 'I placed multiple orders that were automatically fulfilled. Will I receive an invoice for each order?', answer: 'No. We will combine all of your outstanding orders into a single invoice that is sent at the end of the month.'}
  ];
  document.title = 'FAQ';
  return (
    <div className="content-container">
      <h1 className="content-container-section-name">FAQ</h1>
      {questionsAndAnswers.map((faq, index) => {
        return (
          <div className="faq-container-text" key={index}>
            <h2>Q. {faq.question}</h2>
            <p>A. {faq.answer}</p>
          </div>
        );
      })}
    </div>
  );
}

export default FAQ;
