import '../App.css';
import React, {useState, useEffect} from 'react';


function AddNewLine(text, outputArray){
  var brTextSplit = text.split("<br>")
    for(var j=0; j<brTextSplit.length-1; j++){
      outputArray.push(brTextSplit[j])
      outputArray.push(<br></br>)
      outputArray.push(<br></br>)
    }
    outputArray.push(brTextSplit[brTextSplit.length-1])
}

/**
 * Component for the header at the top of the page.
 * @return {Component}
 */
function Notice({text,link,word}) {
  var textSplit = text.split("<here>");
  var output = [];
  for(var i=0; i<textSplit.length-1; i++){
    AddNewLine(textSplit[i],output);
    output.push(<a href={link[i]}>{word[i]}</a>)
  }
  AddNewLine(textSplit[textSplit.length-1],output);
  
  output = [
    <b>Please note: </b>,
    "The Thinkbox UBL Marketplace will no longer be available for new purchases starting April 30, 2025. The UBL feature in Deadline 10 is now available via the new AWS Deadline Cloud UBL. For detailed instructions, please follow ",
    <a target="_blank" href="https://awsthinkbox.zendesk.com/hc/en-us/articles/22883209044759-AWS-Deadline-Cloud-UBL-for-Deadline-10-on-AWS">this link</a>,
    ".",
    <br />,
    <br />,
    "We're actively working on adding VRay and RenderMan support in AWS Deadline Cloud UBL. UBL support will not be offered for Yeti. However, you can continue to use Yeti via BYOL. For more details, please refer to ",
    <a href="/faq">this FAQ</a>,
    <br />,
    <br />,
    "You can continue to use your existing purchases until September 30, 2025. For any questions about existing UBL purchases, please reach out to: ",
    <a href="mailto:support@awsthinkbox.zendesk.com">support@awsthinkbox.zendesk.com</a>,
    "."
  ]

  return (
    <div className="notice-container">
        {output}
    </div>
  );
}


export default Notice;
