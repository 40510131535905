import React from 'react';
import '../App.css';

/**
 * Component for the Floating Licenses section of the website.
 * @return {Component}
 */
function FloatingLicenses() {
  document.title = 'Floating Licenses';
  return (
    <div className="content-container">
      <h1 className="content-container-section-name">Floating Licenses</h1>
      <p className="content-container-text">
        Traditional floating licenses are still available for all Thinkbox
        products, and can be generated using the Thinkbox floating
        license portal. See the
        <a target="_blank" rel="noopener noreferrer" href="https://docs.thinkboxsoftware.com/products/deadline/10.4/1_User%20Manual/manual/floating-license-portal.html">
          &nbsp;Floating License Portal&nbsp;
        </a>
        page in the Deadline 10 documentation for more information.
      </p>
    </div>
  );
}

export default FloatingLicenses;
